    import React, { useState, useEffect } from 'react';
    import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Menu,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    } from '@mui/material';
    import axios from 'axios';
    import Tabs from '@mui/material/Tabs';
    import Tab from '@mui/material/Tab';
    import Typography from '@mui/material/Typography';
    import DatePicker from '@mui/lab/DatePicker';

    
    const Training = () => {
        const[jobsData,setJobsData]=useState([]);
        const [openCreateJobPopup,SetOpenCreateJobPopup]=useState(false);
        const [openBookingPopup,setOpenBookingPopup]=useState(false);
        const [selectedJobDetails, setSelectedJobDetails] = useState(null);
        const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
        const [selectedTab, setSelectedTab] = useState('details');
        const [selectedJob, setSelectedJob] = useState(null);
        const [openEditDialog, setOpenEditDialog] = useState(false);
        
        const [newJobData, setNewJobData] = useState({
            "plan_name": "",
            "sports": [], // Initialize as an empty array
            "event_type": "",
            "start_date": "",
            "end_date": "",
            "location": "",
            "max_members": "",
            "min_members": "",
            "coach": "",  
            "start_time": "",
            "end_time": "",
            "fees_type": "",
            "fees_frequency": "",
            "fees": "",
            "notes": ""
        });
        
        

        const[editedEventData,setEditedEventData]=useState({
            "plan_name": "",
            "sports": [], // Initialize as an empty array
            "event_type": "",
            "start_date": "",
            "end_date": "",
            "location": "",
            "max_members": "",
            "min_members": "",
            "coach": "",  
            "start_time": "",
            "end_time": "",
            "fees_type": "",
            "fees_frequency": "",
            "fees": "",
            "notes": ""
    });

    const [newBookingData, setNewBookingData] = useState({
        user: '',
        trainings: []
    });

    const [sportsOptions, setSportsOptions] = useState([]);
    const [coachOptions, setCoachOptions] = useState([]);
    const [feesTypeOptions, setFeesTypeOptions] = useState([]);
    const[user,setUser] =useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationMeta, setPaginationMeta] = useState({
        count: 0,
        next: null,
        previous: null,
    });

  useEffect(() => {
    fetchJobs(page);
  }, [page]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const fetchJobs = async (page) => {
    try {
      const response = await axios.get(`${backendUrl}/api/training/?page=${page}`);
      setJobsData(response.data.results); // Update state with new data
      setTotalPages(Math.ceil(response.data.count / 1)); // Adjust page size if necessary
      setPaginationMeta({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
      });
    } catch (err) {
      console.log("Error fetching jobs:", err);
    }
  };

  const handlePreviousPage = () => {
    if (paginationMeta.previous) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationMeta.next) {
      setPage(page + 1);
    }
  };

  const buttonStyle = {
    margin: '8px',
    padding: '8px 16px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#b0bec5',
    cursor: 'not-allowed',
  };


        const handleCreateJobs = async () => {
            try {
                // Convert sports to an array of IDs if it's not already
                const sportsArray = Array.isArray(newJobData.sports) ? newJobData.sports : [newJobData.sports];
        
                const payload = {
                    plan_name: newJobData.plan_name,
                    sports: sportsArray, // Assuming sports is an array of sport IDs
                    event_type: newJobData.event_type,
                    start_date: newJobData.start_date,
                    end_date: newJobData.end_date,
                    location: newJobData.location,
                    max_members: newJobData.max_members,
                    min_members: newJobData.min_members,
                    coach: newJobData.coach,
                    start_time: newJobData.start_time,
                    end_time: newJobData.end_time,
                    fees_type: newJobData.fees_type,
                    fees_frequency: newJobData.fees_frequency,
                    fees: newJobData.fees,
                    notes: newJobData.notes
                };
        
                console.log("Payload:", payload); // Log the payload
        
                const response = await axios.post(`${backendUrl}/api/training/`, payload);
                fetchJobs();
                handleCloseCreateJobPopup();
            } catch (err) {
                console.log(err);
            }
        };
        
        
        
        
        
        
        const handleOpenBookingJobPopup = () => {
            setOpenBookingPopup(true);
        };

        const handleCloseBookingJobPopup = () => {
            setOpenBookingPopup(false);
        };



        
        const handleOpenCreateJobPopup = () => {
            SetOpenCreateJobPopup(true)
            };
    
            const handleCloseCreateJobPopup = () => {
            SetOpenCreateJobPopup(false);
            };




        const handleChange = (e, isCreating) => {
            const { name, value } = e.target;
            if (name === "activities") {
                // For activities field, no need to split, directly set the value
                if (isCreating) {
                    setNewJobData(prevData => ({
                        ...prevData,
                        [name]: value
                    }));
                } else {
                    setEditedEventData(prevData => ({
                        ...prevData,
                        [name]: value
                    }));
                }
            } else {
                // Handle other input fields
                if (isCreating) {
                    setNewJobData(prevData => ({
                        ...prevData,
                        [name]: value
                    }));
                } else {
                    setEditedEventData(prevData => ({
                        ...prevData,
                        [name]: value
                    }));
                }
            }
        };
        
        
        
        
        
        

        useEffect(()=>{
                fetchJobs();
                fetchDropdownData();
        },[])

        const handleOpenDetailsDialog = (jobs) => {
        setSelectedJobDetails(jobs);
        setOpenDetailsDialog(true);
        console.log("Selected Job Details:", jobs);
        };
        
    const handleCloseDetailsDialog = () => {
        setOpenDetailsDialog(false);
    };


    const handleOpenEditDialog = (jobs) => {
    setSelectedJob(jobs);
    const editedData = { ...jobs };
    setEditedEventData(editedData);
    setOpenEditDialog(true); // Make sure this line is present
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleSaveChanges = async () => {
        try {
        // Check if selectedEvent is not null
        if (!selectedJob) {
            console.error("No event selected for editing.");
            return;
        }
    
        // Send PUT request to update event data
        const response = await axios.put(`${backendUrl}/api/training/${selectedJob.id}/`, editedEventData);
    
        // Check if the request was successful (status code 2xx)
        if (response.status === 200 || response.status === 201) {
            // Fetch updated event data after successful update
            fetchJobs();
            handleCloseEditDialog();
        } else {
            // Log error if response status code is not in the 2xx range
            console.error("Failed to update event data. Unexpected status code:", response.status);
        }
        } catch (err) {
        // Log detailed error message
        console.error("Error updating event data:", err);
    
        // Check if the error has a response and log the server error message
        if (err.response) {
            console.error("Server error message:", err.response.data);
        }
        }
    };



 

    const handlePayOnline = async () => {
        if (!newBookingData.trainings || !newBookingData.user) {
            alert('Please select a training and user.');
            return;
        }
    
        // Assuming trainings is always passed as an array
        const selectedTrainingIds = Array.isArray(newBookingData.trainings) ? newBookingData.trainings : [newBookingData.trainings];
        const selectedTrainings = jobsData.filter(training => selectedTrainingIds.includes(training.id));
        const selectedUser = user.find(user => user.id === newBookingData.user);
    
        if (!selectedTrainings.length || !selectedUser) {
            alert('Error: Invalid training or user selection.');
            return;
        }
    
        const userEmail = selectedUser.email;
        const totalAmountInPaise = selectedTrainings.reduce((acc, training) => acc + training.fees * 100, 0); // Calculate total amount in paise
    
        const razorpayOptions = {
            key: 'rzp_test_T3sbbEfXXjKbuu',
            amount: totalAmountInPaise, // Amount in paise
            currency: 'INR',
            name: 'Training Booking',
            description: 'Training Booking',
            image: '', // Your logo URL
            order_id: '', // Leave empty for now
            handler: async function (response) {
                try {
                    // Create training booking
                    const requestData = {
                        trainings: selectedTrainings.map(training => training.id), // Ensure training is a single value, not an array
                        user: selectedUser.id,
                    };
                    console.log('Request Data:', requestData); // Log the request data
                    const bookingResponse = await axios.post(`${backendUrl}/api/trainingbooking/`, requestData);
                    console.log('Booking Response:', bookingResponse.data);
    
                    const invoiceId = bookingResponse.data.invoice_id;
    
                    // Mark invoice as paid
                    await axios.put(`${backendUrl}/api/invoice/${invoiceId}`, {
                        is_paid: true
                    });
    
                    alert('Booking successful!');
                    fetchJobs(); // Assuming fetchJobs() retrieves updated jobs list
                    handleCloseBookingJobPopup();
                } catch (error) {
                    console.error('Error making booking:', error);
                    alert('Booking failed. Please try again.');
                }
            },
            prefill: {
                name: `${selectedUser.first_name} ${selectedUser.last_name}`,
                email: userEmail,
                contact: '9999999999', // Replace with user's contact number if available
            },
            theme: {
                color: '#f26d21', // Your theme color
            },
        };
    
        try {
            const razorpayInstance = new window.Razorpay(razorpayOptions);
            razorpayInstance.open();
        } catch (error) {
            console.error('Error handling payment:', error);
        }
    };
    
    
    

    const handleCash= async () => {
        if (!newBookingData.trainings || !newBookingData.user) {
            alert('Please select a training and user.');
            return;
        }
    
        // Assuming trainings is always passed as an array
        const selectedTrainingIds = Array.isArray(newBookingData.trainings) ? newBookingData.trainings : [newBookingData.trainings];
        const selectedTrainings = jobsData.filter(training => selectedTrainingIds.includes(training.id));
        const selectedUser = user.find(user => user.id === newBookingData.user);
    
        if (!selectedTrainings.length || !selectedUser) {
            alert('Error: Invalid training or user selection.');
            return;
        }
    
        const totalAmountInPaise = selectedTrainings.reduce((acc, training) => acc + training.fees * 100, 0); // Calculate total amount in paise
    
        try {
            // Create training booking
            const requestData = {
                trainings: selectedTrainings.map(training => training.id), // Ensure training is a single value, not an array
                user: selectedUser.id,
            };
            console.log('Request Data:', requestData); // Log the request data
            const bookingResponse = await axios.post(`${backendUrl}/api/trainingbooking/`, requestData);
            console.log('Booking Response:', bookingResponse.data);
    
            const invoiceId = bookingResponse.data.invoice_id;
    
            // Mark invoice as paid and set payment_type to 'Cash'
            await axios.put(`${backendUrl}/api/invoice/${invoiceId}`, {
                is_paid: true,
                payment_type: 'Cash'
            });
    
            alert('Booking successful!');
            fetchJobs(); // Assuming fetchJobs() retrieves updated jobs list
            handleCloseBookingJobPopup();
        } catch (error) {
            console.error('Error making booking:', error);
            alert('Booking failed. Please try again.');
        }
    };
    
    
    

    const handleBookingChange = (e) => {
        const { name, value } = e.target;
        setNewBookingData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    
    const fetchDropdownData = async () => {
        try {
            const sportsResponse = await axios.get(`${backendUrl}/api/activity`);
            const coachResponse = await axios.get(`${backendUrl}/api/coaches/`);
            const feesTypeResponse = await axios.get(`${backendUrl}/api/feestype`);
            const userResponse = await axios.get(`${backendUrl}/api/users/members/`);

            setSportsOptions(sportsResponse.data);
            console.log(sportsResponse)
            console.log("userResponse:",userResponse)
            const coach = Array.isArray(coachResponse.data) ? coachResponse.data : [];
            setCoachOptions(coach);
            setFeesTypeOptions(feesTypeResponse.data);
            const users = userResponse.data.results || userResponse.data;

            setUser(users);
            
        } catch (err) {
            console.error(err);
        }
    };
    
    return (
        <div>
            <h2>Training Information</h2>


            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px', margin: '20px' }}>
    <Button variant="contained" onClick={handleOpenBookingJobPopup} style={{ marginRight: '10px' }}>
        Assign Training
    </Button>
    <Button variant="contained" onClick={handleOpenCreateJobPopup}>
        Add Training
    </Button>
</div>
<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>
        <Table>
        <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
    <TableRow>
        <TableCell style={{ fontWeight: 'bold' }}>Plan Name</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Sports/Events</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Start Date</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Members Enrolled</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
        <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
    </TableRow>
    </TableHead>
    <TableBody>
    {jobsData.map(jobs => (
        <TableRow key={jobs.id}>
        <TableCell>{jobs.plan_name}</TableCell>
        <TableCell>{jobs.sports_names ? jobs.sports_names.join(', ') : '-'}</TableCell>

        <TableCell>{jobs.start_date || 'null'}</TableCell>
        <TableCell>{jobs.members_entrolled || 'null'}</TableCell>
        <TableCell>{jobs.create_date ? new Date(jobs.create_date).toLocaleString() : 'null'}</TableCell>

        <TableCell>
            <div style={{ display: 'flex', justifyContent:'flex-end' }}>
            <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(jobs)} sx={{ marginRight: '8px' }}>Details</Button>
            <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(jobs)}>Edit</Button>
            </div>
            <Menu>
            <MenuItem>Enroll</MenuItem>
            <MenuItem>Register</MenuItem>
            </Menu>
        </TableCell>
        </TableRow>
    ))}
    </TableBody>

        </Table>

    <Dialog open={openCreateJobPopup} onClose={handleCloseCreateJobPopup}>
            <DialogTitle>Create Activity</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
            <Typography variant="subtitle1" gutterBottom>Job Title</Typography>
            <TextField
    fullWidth
    label="Plan Name"
    name="plan_name"
    value={newJobData.plan_name}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />

<FormControl fullWidth margin="normal">
    <InputLabel>Activities</InputLabel>
    <Select
        name="activities"
        multiple
        value={newJobData.sports} // Assuming newJobData.sports stores the selected activity IDs
        onChange={(e) => {
            const selectedActivityIds = e.target.value;
            setNewJobData(prevData => ({
                ...prevData,
                sports: selectedActivityIds,
            }));
        }}
    >
        {sportsOptions.map(option => (
            <MenuItem key={option.id} value={option.id}>{option.activity_name}</MenuItem>
        ))}
    </Select>
</FormControl>




    {/* <Typography variant="subtitle1" gutterBottom>Event Type</Typography>
    <TextField
    fullWidth
    label="Event Type "
    name="event_type"
    
    value={newJobData.validity_days}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    InputLabelProps={{
        shrink: true,
    }}
    /> */}
   <Typography variant="subtitle1" gutterBottom>Start Date</Typography>
<TextField
    fullWidth
    type="date"
    label="Start Date"
    name="start_date"
    value={newJobData.start_date}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    InputLabelProps={{
        shrink: true,
    }}
/>

<Typography variant="subtitle1" gutterBottom>End Date</Typography>
<TextField
    fullWidth
    type="date"
    label="End Date"
    name="end_date"
    value={newJobData.end_date}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    InputLabelProps={{
        shrink: true,
    }}
/>


<Typography variant="subtitle1" gutterBottom>Location</Typography>
    <TextField
    fullWidth
    label="Location"
    name="location"
    value={newJobData.location}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />


<Typography variant="subtitle1" gutterBottom>Max Members</Typography>
    <TextField
    fullWidth
    label="Max Members"
    name="max_members"
    value={newJobData.max_members}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />



<Typography variant="subtitle1" gutterBottom>Min Members</Typography>
    <TextField
    fullWidth
    label="Min Members"
    name="min_members"
    value={newJobData.min_members}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />


<Typography variant="subtitle1" gutterBottom>Coach</Typography>
<FormControl fullWidth margin="normal">
    <InputLabel>Coach</InputLabel>
    <Select
        name="coach"
        value={newJobData.coach}
        onChange={(e) => handleChange(e, true)}
    >
        {coachOptions.map(coach => (
            <MenuItem key={coach.id} value={coach.id}>{coach.first_name}</MenuItem>
        ))}
    </Select>
</FormControl>


<Typography variant="subtitle1" gutterBottom>Start Time</Typography>
    <TextField
    fullWidth
    label="Start Time"
    name="start_time"
    value={newJobData.start_time}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />

<Typography variant="subtitle1" gutterBottom>End Time</Typography>
    <TextField
    fullWidth
    label="End Time"
    name="end_time"
    value={newJobData.end_time}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />
<Typography variant="subtitle1" gutterBottom>Fees Type</Typography>
<FormControl fullWidth margin="normal">
    <InputLabel>Fees Type</InputLabel>
    <Select
        name="fees_type"
        value={newJobData.fees_type}
        onChange={(e) => handleChange(e, true)}
    >
        {feesTypeOptions.map(type => (
            <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
        ))}
    </Select>
</FormControl>





<Typography variant="subtitle1" gutterBottom>Fees</Typography>
    <TextField
    fullWidth
    label="Fees"
    name="fees"
    value={newJobData.fees}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />

<Typography variant="subtitle1" gutterBottom>Notes</Typography>
    <TextField
    fullWidth
    label="Notes"
    name="notes"
    value={newJobData.notes}
    onChange={(e) => handleChange(e, true)}
    margin="normal"
    />

    
            
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleCloseCreateJobPopup}  color="primary">
                Cancel
            </Button>
            <Button variant="contained" onClick={handleCreateJobs} color="primary">
                Create
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
    open={openDetailsDialog}
    onClose={handleCloseDetailsDialog}
    maxWidth={false}
      PaperProps={{
        style: {
          width: '80vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}
    >
    {/* <DialogTitle>Activity Details</DialogTitle> */}
    <DialogContent>
        <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
        >
        <Tab label="Details" value="details" sx={{ color: 'black', fontWeight: 'bold' }} />
        <Tab label="Enroll" value="enroll"  sx={{ color: 'black', fontWeight: 'bold' }} />
        <Tab label="Reserve" value="reserve" sx={{ color: 'black', fontWeight: 'bold' }} />
        </Tabs>
        {selectedTab === 'details' && (
    <div>
        <Typography variant="h6"  marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} style={{ fontWeight: 'bold' }} justifyContent={'center'}>Training Details</Typography>
        <TableContainer>
        <Table>
    <TableBody>
        <TableRow>
        <TableCell>Plan Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.plan_name : ''}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Sports Names</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.sports_names : ''}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Start Date</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.start_date : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Members Entrolled</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.members_entrolled : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Create date</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.create_date : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>End Date</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.end_date : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Location</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.location : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Min Members</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.min_members : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Max Members</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.max_members : 'Null'}</TableCell>
        </TableRow>

        <TableRow>
        <TableCell>Start Time</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.start_time : 'Null'}</TableCell>
        </TableRow>

        <TableRow>
        <TableCell>End Time</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.end_time : 'Null'}</TableCell>
        </TableRow>

        <TableRow>
        <TableCell>Fees Type</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.fees_type : 'Null'}</TableCell>
        </TableRow>

        <TableRow>
        <TableCell>Fees</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.fees : 'Null'}</TableCell>
        </TableRow>

        <TableRow>
        <TableCell>Notes</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.notes : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Coach</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.coach : 'Null'}</TableCell>
        </TableRow>
        <TableRow>
        <TableCell>Event Type</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.event_type : 'Null'}</TableCell>
        </TableRow>
    
    </TableBody>
    </Table>

    </TableContainer>

    </div>
    )}

        {selectedTab === 'enroll' && (
        <div>
            {/* Enroll content */}
        </div>
        )}
        {selectedTab === 'reserve' && (
        <div>
            {/* Reserve content */}
        </div>
        )}
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseDetailsDialog} variant='contained' color="primary">
        Close
        </Button>
    </DialogActions>
    </Dialog>


    <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth={false}
      PaperProps={{
        style: {
          width: '80vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}>
            <DialogTitle style={{ fontWeight: 'bold' }}>
      Edit Training
    </DialogTitle>
            <DialogContent>
            <Typography variant="subtitle1" gutterBottom>Plan Name</Typography>
            <TextField
                fullWidth
                // label="Event Name"
                placeholder='Plan Name'
                name="plan_name"
                value={editedEventData.plan_name}
                onChange={handleChange}
                margin="normal"
            />
            <Typography variant="subtitle1" gutterBottom>Event Type</Typography>
            <TextField
                fullWidth
                // label="HEntry"
                name="event_type"
                value={editedEventData.event_type}
                onChange={handleChange}
                margin="normal"
            />

    {/* <Typography variant="subtitle1" gutterBottom>Registered Peoples</Typography>
            <TextField
                fullWidth
                // label="Monthly Fees"
                name="registered_peoples"
                value={editedEventData.registered_peoples}
                onChange={handleChange}
                margin="normal"
            />
            <Typography variant="subtitle1" gutterBottom>Maximum Participants</Typography>
            <TextField
                fullWidth
                // label="Max Team Size"
                name="maximum_participants"
                value={editedEventData.maximum_participants}
                onChange={handleChange}
                margin="normal"
            /> */}

    <Typography variant="subtitle1" gutterBottom>Location</Typography>
            <TextField
                fullWidth
                // label="Max Time Limit"
                name="location"
                value={editedEventData.location}
                
                onChange={handleChange}
                margin="normal"
            />

<Typography variant="subtitle1" gutterBottom>Max Members</Typography>
            <TextField
                fullWidth
                // label="Max Time Limit"
                name="max_members"
                value={editedEventData.max_members}
                
                onChange={handleChange}
                margin="normal"
            />

<Typography variant="subtitle1" gutterBottom>Min Members</Typography>
            <TextField
                fullWidth
                // label="Max Time Limit"
                name="min_members"
                value={editedEventData.min_members}
                
                onChange={handleChange}
                margin="normal"
            />


<Typography variant="subtitle1" gutterBottom>Fees Type</Typography>
            <TextField
                fullWidth
                // label="Max Time Limit"
                name="fees_type"
                value={editedEventData.fees_type}
                
                onChange={handleChange}
                margin="normal"
            />


<Typography variant="subtitle1" gutterBottom>Fees</Typography>
            <TextField
                fullWidth
                // label="Max Time Limit"
                name="fees"
                value={editedEventData.fees}
                
                onChange={handleChange}
                margin="normal"
            />


<Typography variant="subtitle1" gutterBottom>Notes</Typography>
            <TextField
                fullWidth
                // label="Max Time Limit"
                name="notes"
                value={editedEventData.notes}
                
                onChange={handleChange}
                margin="normal"
            />
            
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseEditDialog} variant='contained' color="primary">
                Cancel
            </Button>
            <Button onClick={handleSaveChanges} variant='contained' color="primary">
                Save Changes
            </Button>
            </DialogActions>
        </Dialog>


{/* for Book the Training */}

<Dialog open={openBookingPopup} onClose={handleCloseCreateJobPopup}>
      <DialogTitle>Create Booking</DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <Typography variant="subtitle1" gutterBottom>User</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>User</InputLabel>
          <Select
            name="user"
            value={newBookingData.user || ''}
            onChange={handleBookingChange}
          >
            {user.map((userItem) => (
              <MenuItem key={userItem.id} value={userItem.id}>{userItem.first_name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Trainings</InputLabel>
          <Select
            name="trainings"
            value={newBookingData.trainings || ''}
            onChange={handleBookingChange}
          >
            {jobsData.map((training) => (
              <MenuItem key={training.id} value={training.id}>{training.plan_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCloseBookingJobPopup} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handlePayOnline} color="primary">
          Assign
        </Button>
        <Button variant="contained" onClick={handleCash} color="primary">
          Accept Cash
        </Button>
      </DialogActions>
    </Dialog>

        </div>
    )
    }


    export default Training