import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
const Sports = () => {
  const [activityData, setActivityData] = useState([]);
  const [feesTypes, setFeesTypes] = useState([]);
  const [openCreateActivityPopup, setOpenCreateActivityPopup] = useState(false);
  const [newActivityData, setNewActivityData] = useState({
    activity_name: '',
    current_hourly_fees: '',
    current_monthly_fees: '',
    max_team_size: '',
    max_time_limit: '',
    available_court: '',
    fees_type: ''
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editedActivityData, setEditedActivityData] = useState({
    activity_name: '',
    price: '',
    current_monthly_fees: '',
    max_team_size: '',
    max_time_limit: '',
    fees_type: ''
  });
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedActivityDetails, setSelectedActivityDetails] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState('details'); // Set default tab to 'details'
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
    fetchFeesTypes();
  }, []);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/activity`);
      setActivityData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFeesTypes = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/fees_types`);
      console.log("Fetched fees types:", response.data); // Debug log
      setFeesTypes(response.data);
    } catch (error) {
      console.log("Error fetching fees types:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (openEditDialog) {
      setEditedActivityData(prevData => ({
        ...prevData,
        [name]: value
      }));
    } else {
      setNewActivityData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleOpenCreateActivityPopup = () => {
    setOpenCreateActivityPopup(true);
  };

  const handleCloseCreateActivityPopup = () => {
    setOpenCreateActivityPopup(false);
  };

  const handleCreateActivity = async () => {
    try {
      await axios.post(`${backendUrl}/api/activity/`, newActivityData);
      fetchData();
      handleCloseCreateActivityPopup();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenEditDialog = (activity) => {
    setSelectedActivity(activity);
    const editedData = { ...activity };
    setEditedActivityData(editedData);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      const { activity_image, ...dataWithoutImage } = editedActivityData;
      await axios.put(`${backendUrl}/api/activity/${selectedActivity.id}`, dataWithoutImage);
      fetchData();
      handleCloseEditDialog();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDetailsDialog = (activity) => {
    setSelectedActivityDetails(activity);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleEnrollRegisterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEnrollRegisterClose = () => {
    setAnchorEl(null);
  };
  const handlenavigation=()=>{
    navigate('/BookingSports');
  }

  return (
    <div>
      <h2>Activity Information</h2>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
      <Button variant="contained" onClick={handlenavigation} style={{margin:'10px'}}>
          Reserve
        </Button>
        <Button variant="contained" onClick={handleOpenCreateActivityPopup} style={{margin:'10px'}}>
          Add Activities
        </Button>
      </div>
      <Dialog open={openCreateActivityPopup} onClose={handleCloseCreateActivityPopup}>
        <DialogTitle>Create Activity</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Activity Name"
            name="activity_name"
            value={newActivityData.activity_name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Hourly Fees"
            name="current_hourly_fees"
            value={newActivityData.current_hourly_fees}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Monthly Fees"
            name="current_monthly_fees"
            value={newActivityData.current_monthly_fees}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Max Team Size"
            name="max_team_size"
            value={newActivityData.max_team_size}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Max Time Limit"
            name="max_time_limit"
            value={newActivityData.max_time_limit}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Available Court"
            name="available_court"
            value={newActivityData.available_court}
            onChange={handleChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Fees Type</InputLabel>
            <Select
              name="fees_type"
              value={newActivityData.fees_type}
              onChange={handleChange}
            >
              {feesTypes.map(feesType => (
                <MenuItem key={feesType.id} value={feesType.id}>
                  {feesType.type_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseCreateActivityPopup} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCreateActivity} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Sports Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Available image</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityData.map(activity => (
              <TableRow key={activity.id}>
                <TableCell>{activity.id}</TableCell>
                <TableCell>{activity.activity_name}</TableCell>
                <TableCell>{activity.price}</TableCell>
                <TableCell>
                  <img src={activity.activity_image} alt="Activity" style={{ maxWidth: '100px' }} />
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(activity)}>Details</Button>
                    <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(activity)}>Edit</Button>
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleEnrollRegisterClose}
                    >
                      <MenuItem onClick={() => handleEnrollRegisterClose()}>Enroll</MenuItem>
                      <MenuItem onClick={() => handleEnrollRegisterClose()}>Register</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
          <DialogTitle>Edit Activity</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Activity Name"
              name="activity_name"
              value={editedActivityData.activity_name}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Price"
              name="price"
              value={editedActivityData.price}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Monthly Fees"
              name="current_monthly_fees"
              value={editedActivityData.current_monthly_fees}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Max Team Size"
              name="max_team_size"
              value={editedActivityData.max_team_size}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Max Time Limit"
              name="max_time_limit"
              value={editedActivityData.max_time_limit}
              onChange={handleChange}
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Fees Type</InputLabel>
              <Select
                name="fees_type"
                value={editedActivityData.fees_type}
                onChange={handleChange}
              >
                {feesTypes.map(feesType => (
                  <MenuItem key={feesType.id} value={feesType.id}>
                    {feesType.type_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveChanges} color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDetailsDialog}
          onClose={handleCloseDetailsDialog}
          sx={{
            maxWidth: 'md',
            width: '80%',
            maxHeight: '80%',
            margin: 'auto',
            p: 1,
            overflow: 'auto',
          }}
        >
          <DialogContent>
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Details" value="details" />
              <Tab label="Enroll" value="enroll" />
              <Tab label="Reserve History" value="reserve" />
            </Tabs>
            {selectedTab === 'details' && (
              <div>
                <Typography variant="h6">Activity Details</Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Activity Name</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.activity_name : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Activity Image</TableCell>
                        <TableCell>
                          {selectedActivityDetails ? (
                            <img src={selectedActivityDetails.activity_image} alt="Activity" style={{ maxWidth: '100px' }} />
                          ) : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Members Allowed</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.members_allowed : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Price</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.price : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Multiplayer</TableCell>
                        <TableCell>{selectedActivityDetails ? (selectedActivityDetails.multiplayer ? 'Yes' : 'No') : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Max Team Size</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.max_team_size : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Current Monthly Fees</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.current_monthly_fees : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Max Time Limit</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.max_time_limit : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Min Time Limit</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.min_time_limit : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Facility Open From Time</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.facility_open_from_time : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Facility Open To Time</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.facility_open_to_time : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Available Court</TableCell>
                        <TableCell>{selectedActivityDetails ? selectedActivityDetails.available_court : ''}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
            {selectedTab === 'enroll' && (
              <div>
                {/* Enroll content */}
              </div>
            )}
            {selectedTab === 'reserve' && (
  <div>
    <Typography variant="h6">Reservations History</Typography>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight:'bold'}}>User</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Time Slot</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Reservation Date</TableCell>
            {/* <TableCell>Price</TableCell>
            <TableCell>Payment ID</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedActivityDetails && selectedActivityDetails.reservations.map((reservation) => (
            <TableRow key={reservation.reservation_id}>
              <TableCell>{reservation.user ? `${reservation.user.first_name}` : 'Unknown'}</TableCell>
              <TableCell>{reservation.time_slot}</TableCell>
              <TableCell>{reservation.reservation_date}</TableCell>
              {/* <TableCell>{reservation.price}</TableCell>
              <TableCell>{reservation.payment_id}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
)}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDetailsDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  
  export default Sports;
  
