import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate hooks
import NavBar from './components/NavBar';
import Home from './components/Home';
import Sports from './components/Sports';
import Login from './components/Login';
import AdminUser from './components/AdminUser';
import Events from './components/Events';
import Jobs from './components/Jobs';
import Coaches from './components/Coaches';
import Visitors from './components/Visitors';
import Training from './components/Training';
import Permissions from './components/Permissions';
import Reporting from './components/Reporting';
import Finance from './components/Finance';
import PaidInvoice from './components/PaidInvoice';
import Members from './components/Members';
import Sponsor from './components/Sponsor';
import UserVerify from './components/UserVerify';
import UserCreate from './components/UserCreate';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import BookingSports from './BookingComponent/BookingSports';
import ConfirmBooking from './BookingComponent/ConfirmBooking';
import BookingWellness from './BookingComponent/BookingWellness';
import BookedEvents from './components/BookedEvents';
import BookedTrainings from './components/BookedTrainings';
import RegisterSports from './BookingComponent/RegisterSports';
import Registration from './components/Registration';
import TermsAndConditions from './components/TermsAndConditions';
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginRoute = location.pathname === '/login';

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log(token);
    if (!token && !isLoginRoute) {
      navigate('/login');
    } else if (token && isLoginRoute) {
      navigate('/home');
    }
  }, [isLoginRoute, navigate]);

  return (
    <div className="App">
      {!isLoginRoute && <NavBar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sports"
          element={
            <ProtectedRoute>
              <Sports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/events"
          element={
            <ProtectedRoute>
              <Events />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <ProtectedRoute>
              <AdminUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobs"
          element={
            <ProtectedRoute>
              <Jobs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/coaches"
          element={
            <ProtectedRoute>
              <Coaches />
            </ProtectedRoute>
          }
        />
        <Route
          path="/visitors"
          element={
            <ProtectedRoute>
              <Visitors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/training"
          element={
            <ProtectedRoute>
              <Training />
            </ProtectedRoute>
          }
        />
        <Route
          path="/permissions"
          element={
            <ProtectedRoute>
              <Permissions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reporting"
          element={
            <ProtectedRoute>
              <Reporting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/finance"
          element={
            <ProtectedRoute>
              <Finance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/paid-invoice"
          element={
            <ProtectedRoute>
              <PaidInvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members"
          element={
            <ProtectedRoute>
              <Members />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sponsors"
          element={
            <ProtectedRoute>
              <Sponsor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/UserCreate"
          element={
            <ProtectedRoute>
              <UserCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/UserVerify"
          element={
            <ProtectedRoute>
              <UserVerify />
            </ProtectedRoute>

          
          }
        />

<Route
          path="/BookingSports"
          element={
            <ProtectedRoute>
              <BookingSports />
            </ProtectedRoute>

          
          }
        />


<Route
          path="/ConfirmBooking"
          element={
            <ProtectedRoute>
              <ConfirmBooking />
            </ProtectedRoute>

          
          }
        />

<Route
          path="/BookingWellness"
          element={
            <ProtectedRoute>
              <BookingWellness />
            </ProtectedRoute>

          
          }
        />

<Route
          path="/booked-trainings"
          element={
            <ProtectedRoute>
              <BookedTrainings />
            </ProtectedRoute>

          
          }
        />


<Route
          path="/booked-events"
          element={
            <ProtectedRoute>
              <BookedEvents />
            </ProtectedRoute>

          
          }
        />


<Route
          path="/register"
          element={
            <ProtectedRoute>
              <Registration />
            </ProtectedRoute>

          
          }
        />
<Route
          path="/terms"
          element={
            <ProtectedRoute>
              <TermsAndConditions />
            </ProtectedRoute>

          
          }
        />
      </Routes>


      
    </div>
  );
}

export default App;
