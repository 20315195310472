import React from 'react';

const TermsAndConditions = () => {
    const styles = {
        container: {
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            lineHeight: '1.6',
        },
        sectionHeader: {
            textAlign: 'left',
            marginBottom: '20px',
        },
        headerParagraph: {
            fontSize: '18px',
            color: '#555',
        },
        headerTitle: {
            fontSize: '28px',
            fontWeight: 'bold',
            color: '#000',
        },
        content: {
            marginBottom: '40px',
        },
        itemTitle: {
            fontSize: '22px',
            fontWeight: 'bold',
            marginTop: '20px',
        },
        itemText: {
            marginBottom: '10px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.sectionHeader}>
                <p style={styles.headerParagraph}>Terms and Conditions</p>
                <h2 style={styles.headerTitle}>ISHTA Terms and Conditions</h2>
            </div>
            <div style={styles.content}>
                <h3 style={styles.itemTitle}>1. Membership:</h3>
                <div style={styles.itemText}>
                    <strong>Eligibility:</strong> Membership is open to individuals of all ages, subject to the approval of ISHTA. Minors must have consent from a parent or legal guardian.
                </div>
                <div style={styles.itemText}>
                    <strong>Application:</strong> All members must complete a membership application form and agree to these terms and conditions.
                </div>
                <div style={styles.itemText}>
                    <strong>Membership Types:</strong> The academy offers various membership plans, including individual, family, student, and senior memberships. Specific benefits and access levels depend on the chosen plan.
                </div>
                <div style={styles.itemText}>
                    <strong>Non-Transferable:</strong> Memberships are personal and cannot be transferred or shared with others.
                </div>

                <h3 style={styles.itemTitle}>2. Payment:</h3>
                <div style={styles.itemText}>
                    <strong>Fees:</strong> Membership fees are due according to the chosen payment schedule (monthly, quarterly, annually). All fees are non-refundable, except as specified under the academy's cancellation policy.
                </div>
                <div style={styles.itemText}>
                    <strong>Payment Methods:</strong> Payments can be made via credit card, debit card, bank transfer, cash, or check.
                </div>
                <div style={styles.itemText}>
                    <strong>Late Payments:</strong> Late payments will incur a service charge of $250. The academy reserves the right to suspend or terminate membership for non-payment.
                </div>
                <div style={styles.itemText}>
                    <strong>Fee Adjustments:</strong> The academy may adjust membership fees with a 30-day notice to members.
                </div>

                <h3 style={styles.itemTitle}>3. Cancellation and Termination:</h3>
                <div style={styles.itemText}>
                    <strong>Member Cancellation:</strong> Members may cancel their membership by providing a 30-day written notice. Early termination fees may apply for canceling a fixed-term membership before the end date.
                </div>
                <div style={styles.itemText}>
                    <strong>Academy Termination:</strong> The academy reserves the right to terminate membership for violations of these terms and conditions, misconduct, or non-payment of dues.
                </div>
                <div style={styles.itemText}>
                    <strong>Freezing Membership:</strong> Members may freeze their membership for medical reasons or extended travel, subject to approval and a fee of $250.
                </div>

                <h3 style={styles.itemTitle}>4. Health and Safety:</h3>
                <div style={styles.itemText}>
                    <strong>Health Assessment:</strong> Members must complete a health and fitness questionnaire before using the facilities. A fitness assessment may also be required.
                </div>
                <div style={styles.itemText}>
                    <strong>Medical Clearance:</strong> Members are responsible for ensuring they are physically fit to participate in activities and should consult a physician if in doubt.
                </div>
                <div style={styles.itemText}>
                    <strong>Injuries:</strong> Members must report any injuries or health emergencies to staff immediately. The academy is not liable for injuries sustained on the premises.
                </div>
                <div style={styles.itemText}>
                    <strong>Hygiene:</strong> Members must maintain personal hygiene, including wiping down equipment after use with provided disinfectants.
                </div>

                <h3 style={styles.itemTitle}>5. Use of Facilities:</h3>
                <div style={styles.itemText}>
                    <strong>Access:</strong> Members must sign in at each visit and present their membership card. Certain areas may be restricted based on membership type.
                </div>
                <div style={styles.itemText}>
                    <strong>Equipment Use:</strong> Members must use equipment safely and follow staff instructions. Misuse of equipment is prohibited.
                </div>
                <div style={styles.itemText}>
                    <strong>Class Enrollment:</strong> Enrollment in classes and programs is subject to availability and may require additional fees. Members must pre-register and arrive on time.
                </div>
                <div style={styles.itemText}>
                    <strong>Facility Hours:</strong> The academy reserves the right to change operating hours and class schedules. Notice of changes will be provided in advance.
                </div>

                <h3 style={styles.itemTitle}>6. Personal Belongings:</h3>
                <div style={styles.itemText}>
                    <strong>Security:</strong> The academy is not responsible for lost or stolen items. Members are encouraged to use lockers and secure their belongings.
                </div>
                <div style={styles.itemText}>
                    <strong>Locker Use:</strong> Items left in lockers overnight will be removed and disposed of after 30 days. Lockers are available on a first-come, first-served basis.
                </div>

                <h3 style={styles.itemTitle}>7. Code of Conduct:</h3>
                <div style={styles.itemText}>
                    <strong>Behavior:</strong> Members must behave respectfully towards staff and other members. Harassment, abuse, or discrimination of any kind is strictly prohibited.
                </div>
                <div style={styles.itemText}>
                    <strong>Attire:</strong> Appropriate gym attire and footwear must be worn at all times.
                </div>
                <div style={styles.itemText}>
                    <strong>Food and Drink:</strong> Food and beverages, except water, are not allowed in workout areas.
                </div>
                <div style={styles.itemText}>
                    <strong>Solicitation:</strong> Members must not engage in any form of solicitation or commercial activity within the academy without prior authorization.
                </div>

                <h3 style={styles.itemTitle}>8. Guest Policy:</h3>
                <div style={styles.itemText}>
                    <strong>Guest Access:</strong> Members may bring guests only with prior approval and subject to guest fees. Guests must complete a registration form and sign a liability waiver.
                </div>
                <div style={styles.itemText}>
                    <strong>Guest Conduct:</strong> Members are responsible for ensuring their guests comply with academy rules. Guests are limited to 2 visits per month.
                </div>

                <h3 style={styles.itemTitle}>9. Privacy and Data Protection:</h3>
                <div style={styles.itemText}>
                    <strong>Personal Information:</strong> The academy collects personal information for managing memberships and providing services. This information will be kept confidential and used in accordance with our privacy policy.
                </div>
                <div style={styles.itemText}>
                    <strong>Communication:</strong> The academy may use member contact information for communication regarding memberships, events, and promotions.
                </div>

                <h3 style={styles.itemTitle}>10. Media Release:</h3>
                <div style={styles.itemText}>
                    <strong>Photography and Filming:</strong> Members grant the academy the right to use photographs and videos taken at the academy for promotional purposes. Members who do not wish to be photographed or filmed should notify staff in writing.
                </div>

                <h3 style={styles.itemTitle}>11. Liability Waiver:</h3>
                <div style={styles.itemText}>
                    <strong>Assumption of Risk:</strong> Members acknowledge that participation in sports and fitness activities involves inherent risks, including injury or death. Members assume all risks and release the academy from any liability arising from participation.
                </div>
                <div style={styles.itemText}>
                    <strong>Indemnification:</strong> Members agree to indemnify and hold harmless the academy from any claims arising out of their use of the facilities.
                </div>

                <h3 style={styles.itemTitle}>12. Amendments:</h3>
                <div style={styles.itemText}>
                    <strong>Policy Changes:</strong> The academy reserves the right to amend these terms and conditions at any time. Members will be notified of any changes in writing or via email.
                </div>
                <div style={styles.itemText}>
                    <strong>Acceptance:</strong> Continued use of the academy facilities following notification of changes constitutes acceptance of those changes.
                </div>

                <h3 style={styles.itemTitle}>13. Miscellaneous:</h3>
                <div style={styles.itemText}>
                    <strong>Entire Agreement:</strong> These terms and conditions constitute the entire agreement between the member and the academy regarding membership.
                </div>
                <div style={styles.itemText}>
                    <strong>Severability:</strong> If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
                </div>
                <div style={styles.itemText}>
                    <strong>Waiver:</strong> The failure of the academy to enforce any right or provision in these terms and conditions will not constitute a waiver of such right or provision unless acknowledged and agreed to by the academy in writing.
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
