
import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Select } from '@mui/material';

const Reporting = () => {
    const [activityData, setActivityData] = useState([]);
    const [openCreateActivityPopup, setOpenCreateActivityPopup] = useState(false);
    const [newActivityData, setNewActivityData] = useState({
     title:'',
     reporting_to_type:'',
    });
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [editedActivityData, setEditedActivityData] = useState({
        title:'',
        reporting_to_type:'',
    });
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedActivityDetails, setSelectedActivityDetails] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTab, setSelectedTab] = useState('details'); // Set default tab to 'details'
  
  
    useEffect(() => {
      fetchData();
    }, []);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/report/`);
        setActivityData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleChange = (e, isEditing) => {
  const { name, value } = e.target;
  if (isEditing) {
    setEditedActivityData(prevData => ({
      ...prevData,
      [name]: value
    }));
  } else {
    setNewActivityData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }
};


      
      
  
    const handleOpenCreateActivityPopup = () => {
      setOpenCreateActivityPopup(true);
    };
  
    const handleCloseCreateActivityPopup = () => {
      setOpenCreateActivityPopup(false);
    };
  
    const handleCreateActivity = async () => {
        
      try {
        setNewActivityData({
            title: '',
            reporting_to_type: '',
          });
        await axios.post(`${backendUrl}/api/report/`, newActivityData);
        fetchData();
        handleCloseCreateActivityPopup();
        setNewActivityData({
            title: '',
            reporting_to_type: '',
          });
      } catch (error) {
        console.log(error);
      }
      
    };
  
    const handleOpenEditDialog = (activity) => {
      setSelectedActivity(activity);
      const editedData = { ...activity };
      setEditedActivityData(editedData);
      setOpenEditDialog(true);
    };
  
    const handleCloseEditDialog = () => {
      setOpenEditDialog(false);
    };
  
    const handleSaveChanges = async () => {
      try {
        const { activity_image, ...dataWithoutImage } = editedActivityData;
        await axios.put(`${backendUrl}/api/report/${selectedActivity.id}/`, dataWithoutImage);
        fetchData();
        handleCloseEditDialog();
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleOpenDetailsDialog = (activity) => {
      setSelectedActivityDetails(activity);
      setOpenDetailsDialog(true);
    };
  
    const handleCloseDetailsDialog = () => {
      setOpenDetailsDialog(false);
    };
  
    const handleEnrollRegisterClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleEnrollRegisterClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <h2>Roll Report</h2>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
          <Button variant="contained" onClick={handleOpenCreateActivityPopup}>
            Add Roll
          </Button>
        </div>
        <Dialog open={openCreateActivityPopup} onClose={handleCloseCreateActivityPopup}>
          <DialogTitle>Create Roll</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={newActivityData.title}
              onChange={handleChange}
              margin="normal"
            />
             <Select
  fullWidth
  label="Reporting To"
  name="reporting_to_type"
  value={newActivityData.reporting_to_type}
  onChange={(e) => handleChange(e, false)} // Pass false for creating
  margin="normal"
>
  {[
    { value: 'AM', label: 'Admin' },
    { value: 'EP', label: 'Employee' },
    { value: 'VS', label: 'Visitor' },
    { value: 'VP', label: 'Vip' },
    { value: 'AR', label: 'Artist' },
    { value: 'IN', label: 'Internal' },
    { value: 'VENDOR', label: 'Vendor' },
  ].map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.value} {/* Display the label text */}
    </MenuItem>
  ))}
</Select>

           
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseCreateActivityPopup} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleCreateActivity} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
        <TableContainer>
          <Table>
            <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Title</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Reporting To</TableCell>
                
                <TableCell style={{ fontWeight: 'bold', textAlign: 'right', verticalAlign: 'bottom' }}>Actions</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {activityData.map(activity => (
                <TableRow key={activity.id}>
                  <TableCell>{activity.id}</TableCell>
                  <TableCell>{activity.title}</TableCell>
                  <TableCell>{activity.reporting_to_type}</TableCell>
                  <TableCell>
                  <div style={{ display: 'flex', justifyContent: 'flex-end',marginRight:'20px' }}>
    <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(activity)}>Details</Button>
    <div style={{ marginRight: '10px' }}></div>
    <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(activity)}>Edit</Button>
</div>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleEnrollRegisterClose}
                    >
                      <MenuItem onClick={() => handleEnrollRegisterClose()}>Enroll</MenuItem>
                      <MenuItem onClick={() => handleEnrollRegisterClose()}>Register</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
          <DialogTitle>Edit Activity</DialogTitle>
          <DialogContent>
  <TextField
    fullWidth
    label="Activity Name"
    name="title"  // Corrected the name attribute
    value={editedActivityData.title}  // Corrected the value assignment
    onChange={(e) => handleChange(e, true)}  // Pass true for editing
    margin="normal"
  />
  <TextField
    fullWidth
    label="Hourly Fees"
    name="reporting_to_type"  // Corrected the name attribute
    value={editedActivityData.reporting_to_type}  // Corrected the value assignment
    onChange={(e) => handleChange(e, true)}  // Pass true for editing
    margin="normal"
  />
</DialogContent>

          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveChanges} color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
    open={openDetailsDialog}
    onClose={handleCloseDetailsDialog}
    sx={{
      maxWidth: 'md',
      width: '80%',
      maxHeight: '80%',
      margin: 'auto',
      p: 1,
      overflow: 'auto',
    }}
  >
    {/* <DialogTitle>Activity Details</DialogTitle> */}
    <DialogContent>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Details" value="details" />
        <Tab label="Enroll" value="enroll" />
        <Tab label="Reserve" value="reserve" />
      </Tabs>
      {selectedTab === 'details' && (
    <div>
      <Typography variant="h6">Role Details</Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>{selectedActivityDetails ? selectedActivityDetails.title : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reporting To</TableCell>
              <TableCell>{selectedActivityDetails ? selectedActivityDetails.reporting_to_type : ''}</TableCell>
            </TableRow>
            
            
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )}
  
      {selectedTab === 'enroll' && (
        <div>
          {/* Enroll content */}
        </div>
      )}
      {selectedTab === 'reserve' && (
        <div>
          {/* Reserve content */}
        </div>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseDetailsDialog} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
  
      </div>
    );
}

export default Reporting