import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Card, CardContent, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';

const Home = () => {
  const [sections, setSections] = useState([
    { title: 'Reserve', image: require('../assets/booking.png'), screen: '/BookingSports' },
    { title: 'Register', image: require('../assets/register.png'), screen: '/register' },
    { title: 'Events', image: require('../assets/calendar.png'), screen: '/events' },
    { title: 'Health', image: require('../assets/healthcare.png'), screen: '/BookingWellness'},
    { title: 'Shop', image: require('../assets/online-shopping.png'), screen: '/shop' },
    { title: 'Sponsor', image: require('../assets/money.png'), screen: '/sponsors' },
    { title: 'Finance', image: require('../assets/financial-profit.png'), screen: '/finance' },
    { title: 'Training', image: require('../assets/plan.png'), screen: '/training' },
    { title: 'Coach', image: require('../assets/coach.png'), screen: '/coaches' },
    
  ]);

  return (
    <div>
      <Typography variant="h5" style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>
        Welcome To ISHTA
      </Typography>
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: 20 }}>
        {sections.map((section, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <NavLink to={section.screen} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Card style={{ cursor: 'pointer' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <img src={section.image} alt={section.title} style={{ width: 100, height: 100 }} />
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 10 }}>
                    {section.title}
                  </Typography>
                </CardContent>
              </Card>
            </NavLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;
