import React from 'react';
import { TextField,Box,Typography,Checkbox } from '@mui/material';

const Permissions = () => {
  return (
    <div>

<Box display="flex" alignItems="center" marginTop={4}>
        
      </Box>
      <Box marginTop={4}>
        <Box border="0.5px solid darkblue" padding={2} marginLeft={1}>
          <Typography variant="h6" fontWeight="bold" gutterBottom textAlign="center">
            Modules:
          </Typography>
          {['Sports', 'Events', 'Reserve', 'Careers'].map((module, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6">{module}</Typography>
              <div style={{ display: 'flex',alignItems:'center' }}>
                <Checkbox color="success" id={`read-${module}`} />
                <label htmlFor={`read-${module}`}>Read</label>
                <Checkbox color="success" id={`update-${module}`} />
                <label htmlFor={`update-${module}`}>Update</label>
                <Checkbox color="success" id={`create-${module}`} />
                <label htmlFor={`create-${module}`}>Create</label>
              </div>
            </div>
          ))}
        </Box>
      </Box>
        

   



    </div>
  )
}

export default Permissions