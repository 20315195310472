import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FinanceSearch from '../SearchComponent/FinanceSearch';

const Visitors = () => {
    const[jobsData,setJobsData]=useState([]);
    const [openCreateJobPopup,SetOpenCreateJobPopup]=useState(false);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedTab, setSelectedTab] = useState('details');
    const [selectedJob, setSelectedJob] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [newJobData, setNewJobData] = useState({
        "user": "",
        "activities": [], 
        "validity_days": "",
        "event_name": ""
      });
      

    const[editedEventData,setEditedEventData]=useState({
        "user": "",
        "activities": [], // Initialize as an empty array
        "validity_days": "",
        "event_name": ""
  });

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    fetchJobs(page);
  }, [page]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchJobs = async (page) => {
    try {
      const response = await axios.get(`${backendUrl}/api/visitors/?page=${page}`);
      console.log(response.data); // Log data to verify
      setJobsData(response.data.results); // Update state with new data
      setFilteredData(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 1)); // Assuming page_size is 1, adjust if necessary
      setPaginationMeta({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
      });
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const handlePreviousPage = () => {
    if (paginationMeta.previous) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationMeta.next) {
      setPage(page + 1);
    }
  };

  const buttonStyle = {
    margin: '8px',
    padding: '8px 16px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#b0bec5',
    cursor: 'not-allowed',
  };

    const handleCreateJobs = async () => {
        try {
            // Convert activities to an array if it's not already one
            const activitiesArray = Array.isArray(newJobData.activities) ? newJobData.activities : [newJobData.activities];
    
            const payload = {
                user: newJobData.user,
                activities: activitiesArray,
                validity_days: newJobData.validity_days,
                event_name: newJobData.event_name,
            };
    
            console.log("Payload:", payload); 
    
            const response = await axios.post(`${backendUrl}/api/visitors/`, payload);
            fetchJobs();
            handleCloseCreateJobPopup();
        } catch (err) {
            console.log(err);
        }
    };
    
    
    
      
    const handleOpenCreateJobPopup = () => {
      SetOpenCreateJobPopup(true)
    };

    const handleCloseCreateJobPopup = () => {
      SetOpenCreateJobPopup(false);
    };

    const handleChange = (e, isCreating) => {
        const { name, value } = e.target;
        if (name === "activities") {
            // For activities field, no need to split, directly set the value
            if (isCreating) {
                setNewJobData(prevData => ({
                    ...prevData,
                    [name]: value
                }));
            } else {
                setEditedEventData(prevData => ({
                    ...prevData,
                    [name]: value
                }));
            }
        } else {
            // Handle other input fields
            if (isCreating) {
                setNewJobData(prevData => ({
                    ...prevData,
                    [name]: value
                }));
            } else {
                setEditedEventData(prevData => ({
                    ...prevData,
                    [name]: value
                }));
            }
        }
    };
    
    
    
    
      
      

    useEffect(()=>{
            fetchJobs();
    },[])

    const handleOpenDetailsDialog = (jobs) => {
      setSelectedJobDetails(jobs);
      setOpenDetailsDialog(true);
      console.log("Selected Job Details:", jobs);
    };
    
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };


  const handleOpenEditDialog = (jobs) => {
  setSelectedJob(jobs);
  const editedData = { ...jobs };
  setEditedEventData(editedData);
  setOpenEditDialog(true); // Make sure this line is present
};

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      // Check if selectedEvent is not null
      if (!selectedJob) {
        console.error("No event selected for editing.");
        return;
      }
  
      // Send PUT request to update event data
      const response = await axios.put(`https://myishta.com/login/api/visitors/${selectedJob.id}/`, editedEventData);
  
      // Check if the request was successful (status code 2xx)
      if (response.status === 200 || response.status === 201) {
        // Fetch updated event data after successful update
        fetchJobs();
        handleCloseEditDialog();
      } else {
        // Log error if response status code is not in the 2xx range
        console.error("Failed to update event data. Unexpected status code:", response.status);
      }
    } catch (err) {
      // Log detailed error message
      console.error("Error updating event data:", err);
  
      // Check if the error has a response and log the server error message
      if (err.response) {
        console.error("Server error message:", err.response.data);
      }
    }
  };



  const handleSearch = (searchParams) => {
    const filtered = jobsData.filter(job => {
      return (
        (!searchParams.firstName || job.user.first_name.toLowerCase().includes(searchParams.firstName.toLowerCase())) &&
        (!searchParams.lastName || job.user.last_name.toLowerCase().includes(searchParams.lastName.toLowerCase())) &&
        (!searchParams.userId || job.user.userId.toLowerCase().includes(searchParams.userId.toLowerCase())) &&
        (!searchParams.telephoneNumber || job.user.telephoneNumber.toLowerCase().includes(searchParams.telephoneNumber.toLowerCase())) &&
        (!searchParams.email || job.user.email.toLowerCase().includes(searchParams.email.toLowerCase())) && 
        (!searchParams.address || job.user.address.toLowerCase().includes(searchParams.address.toLowerCase())) 
      );
    });
    setFilteredData(filtered);
  };
  
  return (
    <div>
        <h2>Visitors Information</h2>
        <FinanceSearch onSearch={handleSearch} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Button variant="contained" onClick={handleOpenCreateJobPopup} >
          Add Visitor
        </Button>
      </div>
     
      <Table>
      <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
  <TableRow>
    <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
    <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
    <TableCell style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
    <TableCell style={{ fontWeight: 'bold' }}>Event Name</TableCell>
    <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
    <TableCell style={{ fontWeight: 'bold' }}>Date Last Visited</TableCell>
    <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
  </TableRow>
</TableHead>
<TableBody>
  {filteredData.map(jobs => (
    <TableRow key={jobs.id}>
      <TableCell>{jobs.user.first_name}</TableCell>
      <TableCell>{jobs.user.last_name || '-'}</TableCell>
      <TableCell>{jobs.user.phone_number || 'null'}</TableCell>
      <TableCell>{jobs.user.email || 'null'}</TableCell>
      <TableCell>{jobs.event_name || 'null'}</TableCell> {/* Corrected line */}
      <TableCell>{jobs.created_at ? new Date(jobs.created_at).toLocaleDateString() : '-'}</TableCell>
      <TableCell>{jobs.last_visited ? new Date(jobs.last_visited).toLocaleDateString() : '-'}</TableCell> {/* Corrected line */}
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(jobs)}>Details</Button>
          <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(jobs)}>Edit</Button>
        </div>
        <Menu>
          <MenuItem>Enroll</MenuItem>
          <MenuItem>Register</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

    </Table>

    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>

<Dialog open={openCreateJobPopup} onClose={handleCloseCreateJobPopup}>
        <DialogTitle>Create Activity</DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
        <Typography variant="subtitle1" gutterBottom>Job Title</Typography>
        <TextField
  fullWidth
  label="User"
  name="user"
  value={newJobData.user}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/>

<Typography variant="subtitle1" gutterBottom>Activities</Typography>
<TextField
  fullWidth
  label="activities"
  name="activities"
  type="textfield"
  value={newJobData.activities}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>

<Typography variant="subtitle1" gutterBottom>Validity Days</Typography>
<TextField
  fullWidth
  label="Validity Days"
  name="validity_days"
  
  value={newJobData.validity_days}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
<Typography variant="subtitle1" gutterBottom>Event Name</Typography>
<TextField
  fullWidth
  label="event_name"
  name="event_name"
  value={newJobData.event_name}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/>

{/* <Typography variant="subtitle1" gutterBottom>Salary</Typography>
<TextField
  fullWidth
  label="salary"
  name="salary"
  value={newJobData.salary}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/> */}
{/* <TextField
  fullWidth
  label="Activities"
  name="activities"
  value={newJobData.activities}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/> */}

          
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseCreateJobPopup}  color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCreateJobs} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={openDetailsDialog}
  onClose={handleCloseDetailsDialog}
  sx={{
    maxWidth: 'md',
    width: '80%',
    maxHeight: '80%',
    margin: 'auto',
    p: 1,
    overflow: 'auto',
  }}
>
  {/* <DialogTitle>Activity Details</DialogTitle> */}
  <DialogContent>
    <Tabs
      value={selectedTab}
      onChange={(event, newValue) => setSelectedTab(newValue)}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="Details" value="details" />
      <Tab label="Enroll" value="enroll" />
      <Tab label="Reserve" value="reserve" />
    </Tabs>
    {selectedTab === 'details' && (
  <div>
    <Typography variant="h6">Job Details</Typography>
    <TableContainer>
    <Table>
  <TableBody>
    <TableRow>
      <TableCell>User Name</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.user.first_name : ''}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>User Mail</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.user.email : ''}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>First Name</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.user.first_name : 'Null'}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Last Name</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.user.last_name : 'Null'}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Phone Number</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.user.phone_number : 'Null'}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Validity Days</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.validity_days : 'Null'}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Event Name</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.event_name : 'Null'}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Created At</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.created_at : 'Null'}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Activities</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.activities : 'Null'}</TableCell>
    </TableRow>
  </TableBody>
</Table>

</TableContainer>

  </div>
)}

    {selectedTab === 'enroll' && (
      <div>
        {/* Enroll content */}
      </div>
    )}
    {selectedTab === 'reserve' && (
      <div>
        {/* Reserve content */}
      </div>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDetailsDialog} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>


<Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Visitors</DialogTitle>
        <DialogContent>
        <Typography variant="subtitle1" gutterBottom>Activities</Typography>
          <TextField
            fullWidth
            // label="Event Name"
            placeholder='Event Name'
            name="activities"
            value={editedEventData.activities}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Validity Days</Typography>
          <TextField
            fullWidth
            // label="HEntry"
            name="validity_days"
            value={editedEventData.validity_days}
            onChange={handleChange}
            margin="normal"
          />

{/* <Typography variant="subtitle1" gutterBottom>Registered Peoples</Typography>
          <TextField
            fullWidth
            // label="Monthly Fees"
            name="registered_peoples"
            value={editedEventData.registered_peoples}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Maximum Participants</Typography>
          <TextField
            fullWidth
            // label="Max Team Size"
            name="maximum_participants"
            value={editedEventData.maximum_participants}
            onChange={handleChange}
            margin="normal"
          /> */}

<Typography variant="subtitle1" gutterBottom>Event Name</Typography>
          <TextField
            fullWidth
            // label="Max Time Limit"
            name="event_name"
            value={editedEventData.event_name}
            
            onChange={handleChange}
            margin="normal"
          />
          {/* <Typography variant="subtitle1" gutterBottom>End Date</Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="min_experience"
            value={editedEventData.min_experience}
        
            onChange={handleChange}
            margin="normal"
          /> */}

{/* <TextField
            fullWidth
            // label="Available Court"
            name="salary"
            value={editedEventData.salary}
            
            onChange={handleChange}
            margin="normal"
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default Visitors