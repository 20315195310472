import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';

const UserVerify = () => {
  const [otp, setOtp] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const[email,setEmail]=useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Load email and phone number from session storage
    const email = sessionStorage.getItem('email');
    const phone_number = sessionStorage.getItem('phone_number');
    
    // If email and phone number are found in session, set them in state
    if (email) setEmail(email);
    if (phone_number) setPhoneNumber(phone_number);
  }, []);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleVerify = async () => {
    const requestData = {
      otp: otp,
      email: email,
      phone_number: sessionStorage.getItem('phone_number'),
      first_name: firstName,
      last_name: lastName,
    };
  
    console.log('Request Data:', requestData); // Log the request data

    try {
      const response = await axios.post(`${backendUrl}/api/users/verify/`, requestData);
      console.log('Response Data:', response.data); // Log the response data
      alert('Verification successful!'); // Alert for successful verification
      navigate('/home');
    } catch (error) {
      console.error('Error verifying user:', error);
      if (error.response) {
        console.error('Server Error:', error.response.data); // Log server error response
      } else {
        console.error('Client Error:', error.message); // Log client error message
      }
      alert('Verification failed. Please try again.'); // Alert for failed verification
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5%',
      }}
    >
      <Box
        sx={{
          border: '1px solid #ccc',
          padding: '20px',
          borderRadius: '5px',
          width: '30%',
          margin: 'auto',
        }}
      >
        <Typography variant="h6">User Verify</Typography>
        <TextField
          label="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          sx={{ marginBottom: '20px' }}
        />

<TextField
          label="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          sx={{ marginBottom: '20px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleVerify}
        >
          Verify
        </Button>
      </Box>
    </Box>
  );
};

export default UserVerify;
