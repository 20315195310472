import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

const ConfirmBooking = () => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [currentMonthDates, setCurrentMonthDates] = useState([]);
  const [activityDetails, setActivityDetails] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activityId, setActivityId] = useState('');
  const [phoneQuery, setPhoneQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [availableSports, setAvailableSports] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    fetchData();
    generateCurrentMonthDates();
    setCurrentDateAsSelected();
    fetchUsers();
    getActivityIdFromStorage();
  }, []);

  useEffect(() => {
    if (activityId) {
      fetchActivityDetails();
    }
  }, [activityId]);

  useEffect(() => {
    if (selectedDate) {
      setLoading(true);
      fetchDataForSelectedDate();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (activityDetails.price !== undefined && selectedTimeSlots.length > 0) {
      const newTotalPrice = selectedTimeSlots.length * activityDetails.price;
      setTotalPrice(newTotalPrice);
    } else {
      setTotalPrice(0);
    }
  }, [selectedTimeSlots, activityDetails]);

  useEffect(() => {
    if (Array.isArray(users)) {
      if (phoneQuery.trim() === '') {
        setFilteredUsers([]);
      } else {
        const filtered = users.filter(user => user.phone_number && user.phone_number.includes(phoneQuery.trim()));
        setFilteredUsers(filtered);
      }
    }
  }, [phoneQuery, users]);
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/users/users/`);
      const fetchedUsers = response.data.results || response.data; // Adjust based on API response structure
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/timeslot`);
      setTimeSlots(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDataForSelectedDate = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/booked-slots/${selectedDate}/${activityId}`);
      setBookedSlots(response.data);
    } catch (error) {
      console.error('Error fetching booked slots:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchActivityDetails = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/activity/${activityId}`);
      setActivityDetails(response.data);
    } catch (error) {
      console.error('Error fetching activity details:', error);
    }
  };

  const fetchAvailableSports = async (lastTimeSlotId) => {
    try {
      const response = await axios.get(`${backendUrl}/api/available-sports/${selectedDate}/${lastTimeSlotId}/`);
      setAvailableSports(response.data);
    } catch (error) {
      console.error('Error fetching available sports:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  const generateCurrentMonthDates = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const dates = [];
    for (let i = today.getDate(); i <= lastDay; i++) {
      const formattedDate = `${year}-${month < 10 ? `0${month}` : month}-${i < 10 ? `0${i}` : i}`;
      dates.push(formattedDate);
    }
    setCurrentMonthDates(dates);
  };

  const setCurrentDateAsSelected = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const formattedDate = `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;
    setSelectedDate(formattedDate);
  };

  const handleDatePress = (date) => {
    setSelectedDate(date);
    setSelectedTimeSlots([]);
    setTotalPrice(0);
  };

  const handleTimePress = (slot) => {
    if (isSlotBooked(slot.id)) {
      alert('Slot Unavailable');
      return;
    }

    const slotIndex = selectedTimeSlots.findIndex(selectedSlot => selectedSlot.id === slot.id);

    if (slotIndex === -1) {
      const isValidContinuousSlot = selectedTimeSlots.length === 0 || selectedTimeSlots.some(selectedSlot => 
        Math.abs(selectedSlot.id - slot.id) === 1
      );

      if (isValidContinuousSlot) {
        setSelectedTimeSlots([...selectedTimeSlots, slot]);
      } else {
        alert('Please select continuous time slots.');
      }
    } else {
      const updatedSlots = [...selectedTimeSlots];
      updatedSlots.splice(slotIndex, 1);
      setSelectedTimeSlots(updatedSlots);
    }
  };

  const isSlotBooked = (slotId) => {
    return bookedSlots.some(slot => slot.time_slots.includes(slotId));
  };

  const handlePhoneInputChange = (event) => {
    setPhoneQuery(event.target.value);
    setSelectedUser(null); // Clear selected user when phone query changes
  };

  const getActivityIdFromStorage = () => {
    const storedActivityId = localStorage.getItem('activityId');
    if (storedActivityId) {
      setActivityId(storedActivityId);
    }
  };

  const handlePayOnline = async () => {
    if (!selectedDate || selectedTimeSlots.length === 0 || !selectedUser) {
      alert('Error', 'Please select a date, time, and user.');
      return;
    }

    const userEmail = selectedUser.id; // Accessing the email property of selectedUser
    const gstPercentage = 18; // GST percentage
    const totalWithGST = totalPrice + (totalPrice * gstPercentage) / 100; // Add 18% GST
    const amountInPaise = Math.round(totalWithGST * 100); // Convert to paise and round off

    console.log('Request Data:', {
      userEmail,
      activityId,
      selectedDate,
      selectedTimeSlots: selectedTimeSlots.map(slot => slot.id)
    });

    // Example Razorpay options, replace with your actual implementation
    const razorpayOptions = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
      amount: amountInPaise, // Amount in paise
      currency: 'INR',
      name: 'Activity Booking',
      description: 'Activity Booking',
      image: '', // Your logo URL
      order_id: '', // Leave empty for now
      handler: async function (response) {
        setPaymentLoading(true);
        try {
          const reservationResponse = await axios.post(`${backendUrl}/api/reservation/`, {
            user: userEmail,
            activity: activityId,
            reservation_date: selectedDate,
            time_slots: selectedTimeSlots.map(slot => slot.id)
          });
          console.log('Reservation Response:', reservationResponse.data);

          const invoiceId = reservationResponse.data.invoice_id;

          await axios.put(`${backendUrl}/api/invoice/${invoiceId}`, {
            is_paid: true,
            payment_type: 'online'
          });

          alert('Success', 'Reservation successful!');

          // Refresh booked slots after successful booking
          setLoading(true);
          await fetchDataForSelectedDate();

          // Fetch available sports based on the last selected time slot
          const lastSelectedSlotId = selectedTimeSlots[selectedTimeSlots.length - 1].id;
          await fetchAvailableSports(lastSelectedSlotId);

        } catch (error) {
          console.error('Error making reservation:', error);
          alert('Error', 'Reservation failed. Please try again.');
        } finally {
          setPaymentLoading(false);
          setLoading(false);
        }
      },
      prefill: {
        name: '', // Prefill user's name if available
        email: userEmail, // Prefill user's email
        contact: '9999999999', // Prefill user's contact number if available
      },
      theme: {
        color: '#f26d21', // Your theme color
      },
    };

    try {
      const razorpayInstance = new window.Razorpay(razorpayOptions);
      razorpayInstance.open();
    } catch (error) {
      console.error("Error handling payment:", error);
    }
  };


  const handleCash = async () => {
    if (!selectedDate || selectedTimeSlots.length === 0 || !selectedUser) {
      alert('Error', 'Please select a date, time, and user.');
      return;
    }
  
    const userEmail = selectedUser.id; // Assuming selectedUser has the ID property for the user
  
    console.log('Request Data:', {
      userEmail,
      activityId,
      selectedDate,
      selectedTimeSlots: selectedTimeSlots.map((slot) => slot.id),
    });
  
    setPaymentLoading(true);
    try {
      // Make the reservation
      const reservationResponse = await axios.post(`${backendUrl}/api/reservation/`, {
        user: userEmail,
        activity: activityId,
        reservation_date: selectedDate,
        time_slots: selectedTimeSlots.map((slot) => slot.id),
      });
  
      // Check if reservation response is successful
      if (!reservationResponse || !reservationResponse.data) {
        throw new Error('Invalid reservation response');
      }
  
      console.log('Reservation Response:', reservationResponse.data);
  
      // Extract necessary data from the response
      const { invoice_id: invoiceId, price: reservationPrice } = reservationResponse.data;
  
      if (!invoiceId || reservationPrice === undefined) {
        throw new Error('Missing invoice ID or price in reservation response');
      }
  
      // Calculate the new amount with 18% GST
      const amountWithGst = reservationPrice + reservationPrice * 0.18;
  
      // Update the invoice with the new amount and mark it as paid
      await axios.put(`${backendUrl}/api/invoice/${invoiceId}`, {
        is_paid: true,
        payment_type: 'cash',
        amount: amountWithGst.toFixed(2), // Format the amount to two decimal places
      });
  
      alert('Success', 'Reservation successful!');
  
      // Refresh booked slots after successful booking
      setLoading(true);
      await fetchDataForSelectedDate();
  
      // Fetch available sports based on the last selected time slot
      const lastSelectedSlotId = selectedTimeSlots[selectedTimeSlots.length - 1].id;
      await fetchAvailableSports(lastSelectedSlotId);
    } catch (error) {
      console.error('Error making reservation:', error);
      alert('Error', 'Reservation failed. Please try again.');
    } finally {
      setPaymentLoading(false);
      setLoading(false);
    }
  };
  
  

  const filteredTimeSlots = timeSlots.filter(slot => {
    const today = new Date().toISOString().split('T')[0];
    const currentTime = new Date().toISOString().split('T')[1];

    if (selectedDate === today) {
      return slot.start_time >= currentTime;
    }
    return true;
  });

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h1>Confirm Booking</h1>
      <div>
        {currentMonthDates.map((date, index) => (
          <Button
            key={index}
            onClick={() => handleDatePress(date)}
            variant={selectedDate === date ? "contained" : "outlined"}
            style={{ margin: '5px', backgroundColor: selectedDate === date ? '#1976D2' : 'inherit', color: selectedDate === date ? 'white' : 'inherit' }}
          >
            {formatDate(date)}
          </Button>
        ))}
      </div>
      <div>
        {loading ? (
          <CircularProgress style={{ margin: '20px' }} />
        ) : (
          <div>
            <h2>Select Time Slots</h2>
            <div>
              {filteredTimeSlots.map((slot, index) => (
                <Button
                  key={index}
                  onClick={() => handleTimePress(slot)}
                  variant={
                    selectedTimeSlots.some(selectedSlot => selectedSlot.id === slot.id)
                      ? "contained"
                      : "outlined"
                  }
                  color={isSlotBooked(slot.id) ? "secondary" : "primary"}
                  style={{
                    margin: '5px',
                    backgroundColor: isSlotBooked(slot.id) ? '#f44336' : selectedTimeSlots.some(selectedSlot => selectedSlot.id === slot.id) ? '#4caf50' : 'black',
                    color: isSlotBooked(slot.id) ? 'white' : 'white',
                  }}
                  disabled={isSlotBooked(slot.id)}
                >
                  {slot.start_time} - {slot.end_time}
                </Button>
              ))}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <div style={{ marginRight: '20px' }}>
                <h2>Activity Name: {activityDetails.activity_name || "N/A"}</h2>
              </div>
              <div style={{ marginLeft: '20px',marginTop:'40px' }}>
                <h3>Other Available Sports In the following timeslot</h3>
                {availableSports.length > 0 ? (
                  <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {availableSports.map(sport => (
                      <li key={sport.id} style={{fontWeight:'bold'}}>{sport.activity_name}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No sports available.</p>
                )}
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <h2>Select User</h2>
              {selectedUser ? (
                <div>
                  <p>User: {selectedUser.first_name}</p>
                  <p>Phone Number: {selectedUser.phone_number}</p>
                </div>
              ) : (
                <p>No user selected</p>
              )}
            </div>

            <div style={{ marginTop: '20px' }}>
              <TextField
                label="Search by Phone Number"
                variant="outlined"
                value={phoneQuery}
                onChange={handlePhoneInputChange}
                style={{ minWidth: '200px' }}
              />
              <div>
                {filteredUsers.length > 0 && (
                  <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {filteredUsers.map(user => (
                      <li key={user.id} onClick={() => setSelectedUser(user)}>
                        {user.email} - {user.phone_number}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handlePayOnline}
        disabled={!selectedDate || selectedTimeSlots.length === 0 || !selectedUser}
        style={{ display: 'block', marginBottom: '10px' }}
      >
        {paymentLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Pay Online'}
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleCash}
        disabled={!selectedDate || selectedTimeSlots.length === 0 || !selectedUser}
        style={{ display: 'block' }}
      >
        {paymentLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Accept Cash'}
      </Button>
    </div>

            <div style={{ marginTop: '20px' }}>
              <h2>Total Price: ₹{totalPrice}<span style={{ fontSize: '16px', marginLeft: '5px' }}>+18% GST</span></h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmBooking;
