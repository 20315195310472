
import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FinanceSearch from '../SearchComponent/FinanceSearch';

const BookedEvents = () => {
    const[jobsData,setJobsData]=useState([]);
    const [openCreateJobPopup,SetOpenCreateJobPopup]=useState(false);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedTab, setSelectedTab] = useState('details');
    const [selectedJob, setSelectedJob] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    
    const [newJobData, setNewJobData] = useState({
      "first_name": "",
      "last_name": "",
      "experiance_years": "",
      "expertise": "",
      "coach_fees":"",
      "phone_number": "",
      "email": "",
      "address": ""
    });
    
    const [editedEventData, setEditedEventData] = useState({
      "first_name": "",
      "last_name": "",
      "experiance_years": "",
      "expertise": "",
      "coach_fees":"",
      "phone_number": "",
      "email": "",
      "address": ""
    });
    
  
  
    const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    fetchJobs(page);
  }, [page]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/bookingevent/?page=${page}`);
      const jobs = response.data;
      console.log("Fetched jobs data:", jobs); // Check the structure here
      setJobsData(jobs.results || []); // Ensure jobsData is an array
      setFilteredData(jobs.results || []); // Ensure filteredData is an array
      setTotalPages(Math.ceil(jobs.count / 10)); // Assuming page_size is 10, adjust if necessary
      setPaginationMeta({
        count: jobs.count,
        next: jobs.next,
        previous: jobs.previous,
      });
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const handlePreviousPage = () => {
    if (paginationMeta.previous) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationMeta.next) {
      setPage(page + 1);
    }
  };

  const buttonStyle = {
    margin: '8px',
    padding: '8px 16px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#b0bec5',
    cursor: 'not-allowed',
  };
  
    const handleCreateJobs = async () => {
      try {
        const payload = {
          first_name: newJobData.first_name,
          last_name: newJobData.last_name,
          experiance_years: newJobData.experiance_years,
          expertise: newJobData.expertise,
          phone_number: newJobData.phone_number,
          email: newJobData.email,
          address: newJobData.address
        };
    
        const response = await axios.post('${backendUrl}/api/coaches/', payload);
        
        // Handle successful creation
        fetchJobs();
        handleCloseCreateJobPopup();
    
      } catch (err) {
        console.log(err);
      }
    };
    
    const handleOpenCreateJobPopup = () => {
      SetOpenCreateJobPopup(true)
    };
  
    const handleCloseCreateJobPopup = () => {
      SetOpenCreateJobPopup(false);
    };
  
    const handleChange = (e, isCreating) => {
      const { name, value } = e.target;
      if (isCreating) {
        setNewJobData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } 
      else {
        setEditedEventData(prevData => ({
          ...prevData,
          [name]: value
        }));
      }
      
    };
  
    useEffect(()=>{
            fetchJobs();
    },[])
  
    const handleOpenDetailsDialog = (jobs) => {
      setSelectedJobDetails(jobs);
      setOpenDetailsDialog(true);
      console.log("Selected Job Details:", jobs);
    };
    
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };
  
  
  const handleOpenEditDialog = (jobs) => {
  setSelectedJob(jobs);
  const editedData = { ...jobs };
  setEditedEventData(editedData);
  setOpenEditDialog(true); // Make sure this line is present
  };
  
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };
  
  const handleSaveChanges = async () => {
    try {
      // Check if selectedEvent is not null
      if (!selectedJob) {
        console.error("No event selected for editing.");
        return;
      }
  
      // Send PUT request to update event data
      const response = await axios.put(`${backendUrl}/api/coaches/${selectedJob.id}/`, editedEventData);
  
      // Check if the request was successful (status code 2xx)
      if (response.status === 200 || response.status === 201) {
        // Fetch updated event data after successful update
        fetchJobs();
        alert('Events Updated Successfully!');
        handleCloseEditDialog();
      } else {
        // Log error if response status code is not in the 2xx range
        console.error("Failed to update event data. Unexpected status code:", response.status);
      }
    } catch (err) {
      // Log detailed error message
      console.error("Error updating event data:", err);
  
      // Check if the error has a response and log the server error message
      if (err.response) {
        console.error("Server error message:", err.response.data);
      }
    }
  };
  
  
  const handleSearch = (searchParams) => {
    const filtered = jobsData.filter(job => {
      return (
        (!searchParams.firstName || job.first_name.toLowerCase().includes(searchParams.firstName.toLowerCase())) &&
        (!searchParams.lastName || job.last_name.toLowerCase().includes(searchParams.lastName.toLowerCase()))&&
        (!searchParams.userId || job.userId.toLowerCase().includes(searchParams.userId.toLowerCase())) &&
        (!searchParams.telephoneNumber || job.telephoneNumber.toLowerCase().includes(searchParams.telephoneNumber.toLowerCase())) &&
        (!searchParams.email || job.email.toLowerCase().includes(searchParams.email.toLowerCase()))&& 
        (!searchParams.address || job.address.toLowerCase().includes(searchParams.address.toLowerCase())) 
  
        // Add other conditions for filtering here
      );
    });
    setFilteredData(filtered);
  };
  
  
  
  
  
  
  
  
  
  
  return (
    <div>
        <h2>Event Booking Information</h2>
        <FinanceSearch onSearch={handleSearch} />
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Button variant="contained" onClick={handleOpenCreateJobPopup} >
          Add Coaches
        </Button>
      </div> */}
        <Table>
  <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
    <TableRow>
    <TableCell style={{ fontWeight: 'bold' }}>Sno</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Event Name</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Entry Fees</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Booking Date</TableCell>
      {/* <TableCell style={{ fontWeight: 'bold' }}>Activites Name</TableCell> */}
      <TableCell  sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {filteredData.map(jobs => (
      <TableRow key={jobs.id}>
         <TableCell>{jobs.id}</TableCell>
        <TableCell>{jobs.event_name}</TableCell>
        <TableCell>{jobs.first_name || '-'}</TableCell>
        <TableCell>{jobs.last_name || 'null'}</TableCell>
        <TableCell>{jobs.entry_fee || 'null'}</TableCell>
        <TableCell>{jobs.booking_date ? new Date(jobs.booking_date).toLocaleString() : '-'}</TableCell>

        {/* <TableCell>{jobs.email || '-'}</TableCell>
        <TableCell>{jobs.address || '-'}</TableCell> */}
  
        {/* <TableCell>{jobs.activities || '-'}</TableCell> */}
       
        <TableCell>
                  <div style={{display:'flex', justifyContent:'flex-end'}}>
                  <Button variant="contained"  color="primary"  onClick={()=>handleOpenDetailsDialog(jobs)}>Details</Button>
                  {/* <Button variant="contained"  color="primary" onClick={()=>handleOpenEditDialog(jobs)} >Edit</Button> */}
                  
                  </div>
                  <Menu
                   
                  >
                    <MenuItem>Enroll</MenuItem>
                    <MenuItem>Register</MenuItem>
                  </Menu>
                </TableCell>
      </TableRow>
    ))}
  </TableBody>
  </Table>

  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>
  
  
  <Dialog open={openCreateJobPopup} onClose={handleCloseCreateJobPopup}>
    <DialogTitle>Create Activity</DialogTitle>
    <DialogContent sx={{ width: '400px' }}>
      <Typography variant="subtitle1" gutterBottom>First Name</Typography>
      <TextField
        fullWidth
        label="First Name"
        name="first_name"
        value={newJobData.first_name}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Last Name</Typography>
      <TextField
        fullWidth
        label="Last Name"
        name="last_name"
        value={newJobData.last_name}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
  
      <Typography variant="subtitle1" gutterBottom>Min Experience</Typography>
      <TextField
        fullWidth
        label="Min Experience"
        name="experiance_years"
        value={newJobData.experiance_years}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
  
      <Typography variant="subtitle1" gutterBottom>Expertise</Typography>
      <TextField
        fullWidth
        label="Expertise"
        name="expertise"
        value={newJobData.expertise}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
  <Typography variant="subtitle1" gutterBottom>Expertise</Typography>
      <TextField
        fullWidth
        label="Coach Fees"
        name="coach_fees"
        value={newJobData.coach_fees}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Phone Number</Typography>
      <TextField
        fullWidth
        label="Phone Number"
        name="phone_number"
        value={newJobData.phone_number}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Email</Typography>
      <TextField
        fullWidth
        label="Email"
        name="email"
        value={newJobData.email}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Address</Typography>
      <TextField
        fullWidth
        label="Address"
        name="address"
        value={newJobData.address}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleCloseCreateJobPopup} color="primary">
        Cancel
      </Button>
      <Button variant="contained" onClick={handleCreateJobs} color="primary">
        Create
      </Button>
    </DialogActions>
  </Dialog>
  
  
      <Dialog
  open={openDetailsDialog}
  onClose={handleCloseDetailsDialog}
  maxWidth={false}
      PaperProps={{
        style: {
          width: '80vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}
  >
  {/* <DialogTitle>Activity Details</DialogTitle> */}
  <DialogContent>
    <Tabs
      value={selectedTab}
      onChange={(event, newValue) => setSelectedTab(newValue)}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="Details" sx={{ color: 'black', fontWeight: 'bold' }} value="details" />
      <Tab label="Enroll" sx={{ color: 'black', fontWeight: 'bold' }} value="enroll" />
      <Tab label="Reserve" sx={{ color: 'black', fontWeight: 'bold' }} value="reserve" />
    </Tabs>
    {selectedTab === 'details' && (
  <div>
    <Typography variant="h5" sx={{fontweight:'bolder',color:'#000'}}>Booked Event Details</Typography>
    <TableContainer>
  <Table>
    <TableBody>
      <TableRow>
        <TableCell>Event Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.event_name : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>First Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.first_name : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Last Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.last_name : 'Null'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Entry Fees</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.entry_fee : 'Null'}</TableCell>
      </TableRow>
  
      <TableRow>
  <TableCell>Booking Date</TableCell>
  <TableCell>
    {selectedJobDetails ? new Date(selectedJobDetails.booking_date).toLocaleString() : 'Null'}
  </TableCell>
</TableRow>

      {/* <TableRow>
        <TableCell>Phone Number</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.phone_number : 'Null'}</TableCell>
      </TableRow>
  
      <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.email : 'Null'}</TableCell>
      </TableRow>
  
      <TableRow>
        <TableCell>Address</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.address : 'Null'}</TableCell>
      </TableRow> */}
     
    </TableBody>
  </Table>
  </TableContainer>
  
  </div>
  )}
  
    {selectedTab === 'enroll' && (
      <div>
        {/* Enroll content */}
      </div>
    )}
    {selectedTab === 'reserve' && (
      <div>
        {/* Reserve content */}
      </div>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDetailsDialog} color="primary">
      Close
    </Button>
  </DialogActions>
  </Dialog>
  
  
  <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Activity</DialogTitle>
        <DialogContent>
        <Typography variant="subtitle1" gutterBottom>Event  Name</Typography>
          <TextField
            fullWidth
            // label="Event Name"
            placeholder='First Name'
            name="first_name"
            value={editedEventData.first_name}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>First Name</Typography>
          <TextField
            fullWidth
            // label="HEntry"
            name="last_name"
            value={editedEventData.last_name}
            onChange={handleChange}
            margin="normal"
          />
  
  {/* <Typography variant="subtitle1" gutterBottom>Registered Peoples</Typography>
          <TextField
            fullWidth
            // label="Monthly Fees"
            name="registered_peoples"
            value={editedEventData.registered_peoples}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Maximum Participants</Typography>
          <TextField
            fullWidth
            // label="Max Team Size"
            name="maximum_participants"
            value={editedEventData.maximum_participants}
            onChange={handleChange}
            margin="normal"
          /> */}
  
  <Typography variant="subtitle1" gutterBottom>Last Name</Typography>
          <TextField
            fullWidth
            // label="Max Time Limit"
            name="experiance_years"
            value={editedEventData.experiance_years}
            
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Entry Fees </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="expertise"
            value={editedEventData.expertise}
        
            onChange={handleChange}
            margin="normal"
          />
  
  <Typography variant="subtitle1" gutterBottom>Booking Date </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="coach_fees"
            value={editedEventData.coach_fees}
        
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Phone Number </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="phone_number"
            value={editedEventData.phone_number}
            
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Email </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="email"
            value={editedEventData.email}
            
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Address </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="address"
            value={editedEventData.address}
            
            onChange={handleChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
  
    </div>
  )
  }

export default BookedEvents