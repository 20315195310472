import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { Card, CardContent, Grid, Typography } from '@mui/material';

const BookingSports = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch activities from the API

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/activity`);
        setActivities(response.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle activity selection
  const handleActivitySelect = (activityId) => {
    localStorage.setItem('activityId', activityId.toString());
  };

  // Render content after loading
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>
        Register Your Sports
      </Typography>
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: 20 }}>
        {loading ? (
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Loading...
          </Typography>
        ) : (
          activities.map((activity, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <NavLink to="/ConfirmBooking" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => handleActivitySelect(activity.id)}>
                <Card style={{ cursor: 'pointer' }}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <img src={activity.activity_image} alt={activity.activity_name} style={{ width: 100, height: 100 }} />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: 10 }}>
                      {activity.activity_name}
                    </Typography>
                  </CardContent>
                </Card>
              </NavLink>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

export default BookingSports;
