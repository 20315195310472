import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const UserCreate = () => {

  
  const [formData, setFormData] = useState({
    email: '',
    phone_number: '+91', // Initialize phone number with +91
    firstName: '',
  });
  const [redirectToVerify, setRedirectToVerify] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the phone number field is being updated
    if (name === 'phone_number') {
      // Ensure phone number always starts with +91
      if (!value.startsWith('+91')) {
        setFormData({ ...formData, [name]: '+91' + value });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSave = async () => {
    // if (formData.email.trim() === '' || formData.phone_number.trim() === '') {
    //   alert('Please provide both email and phone number');
    //   return;
    // }

    try {
      const response = await axios.post(`${backendUrl}/api/users/register/`, formData);
      console.log('Response Data:', response.data);
      alert('OTP Sent successfully!');

      // Store data in session storage
      sessionStorage.setItem('otp', response.data.otp);
      sessionStorage.setItem('email', formData.email);
      sessionStorage.setItem('phone_number', formData.phone_number);

      setRedirectToVerify(true); // Set flag to true to enable redirection
    } catch (error) {
      console.error('Error saving data:', error);
      alert('An error occurred');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
      <div
        style={{
          border: '1px solid #ccc',
          padding: '20px',
          borderRadius: '5px',
          width: '30%',
          margin: 'auto',
        }}
      >
        <Typography style={{ fontWeight: 'bold' }}>UserCreate</Typography>
        <Box display="flex" flexDirection="column">
          {/* <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            size="small"
            margin="dense"
          /> */}
          <TextField
            label="Phone Number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            variant="outlined"
            size="small"
            margin="dense"
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ marginTop: '20px' }}
          >
            Save
          </Button>
          {redirectToVerify && (
            <NavLink to="/UserVerify" style={{ textDecoration: 'none', marginTop: '20px' }}>
              <Button variant="contained" color="secondary">
                Go to Verification
              </Button>
            </NavLink>
          )}
        </Box>
      </div>
    </div>
  );
};

export default UserCreate;
