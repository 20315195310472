import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';

const SponsorSearch = ({ onSearch }) => {
  const [searchParams, setSearchParams] = useState({
    sponsor: '',
    sponsoredPlayer: '',
    sponsoredSport: '',
    sponsoredEvent: '',
    sponsoredPackage: '',
    sponsorshipDate: '',
    amount: '',
  });

  const handleReset = () => {
    const resetParams = {
      sponsor: '',
      sponsoredPlayer: '',
      sponsoredSport: '',
      sponsoredEvent: '',
      sponsoredPackage: '',
      sponsorshipDate: '',
      amount: '',
    };
    setSearchParams(resetParams);
    onSearch(resetParams);
  };

  const handleInputChange = (field, value) => {
    setSearchParams(prevParams => ({
      ...prevParams,
      [field]: value,
    }));
  };

  const handleSearch = () => {
    onSearch(searchParams);
  };

  // Function to convert camelCase to Capitalized Words
  const toPlaceholderText = (text) => {
    return text
      .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter of the string
      .replace(/\b\w/g, (str) => str.toUpperCase()); // Capitalize the first letter of each word
  };

  return (
    <Box position="sticky" top={0} bgcolor="white" zIndex={1000}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '3px' }}>
        {Object.keys(searchParams).map((key) => (
          <TextField
            key={key}
            variant="outlined"
            size="small"
            label={toPlaceholderText(key)} // Convert camelCase to Capitalized Words
            placeholder={toPlaceholderText(key)} // Use the same conversion for the placeholder
            value={searchParams[key]}
            onChange={(e) => handleInputChange(key, e.target.value)}
            InputProps={{ style: { fontSize: '16px', padding: '3px', borderColor: 'blue' } }}
          />
        ))}
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button variant="contained" color="primary" onClick={handleSearch} style={{ fontSize: '10px', padding: '6px', marginRight: '5px' }}>
          Search
        </Button>
        <Button variant="contained" color="secondary" onClick={handleReset} style={{ fontSize: '10px', padding: '6px' }}>
          Reset
        </Button>
      </div>
    </Box>
  );
};

export default SponsorSearch;
